// src/App.js
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import {
  ThemeProvider,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Grid,
  Backdrop,
  useMediaQuery,
  Paper,
  Menu,
  MenuItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './App.css';
import { signIn, signUp, resetPassword } from './authService';
import PrivateRoute from './PrivateRoute';
import theme from './theme';
import ReportPage from './ReportPage';

const ProfilePage = lazy(() => import('./ProfilePage'));
const VerifyPage = lazy(() => import('./VerifyPage'));
const AdminPage = lazy(() => import('./AdminPage'));
const FileContentPage = lazy(() => import('./FileContentPage'));
const CameraInfoPage = lazy(() => import('./CameraInfoPage'));
const FileContentPageA = lazy(() => import('./FileContentPageA'));
const VideoContentPage = lazy(() => import('./VideoContentPage'));
const IPAddressesPage = lazy(() => import('./IPAddressesPage'));
const CameraLocationsPage = lazy(() => import('./CameraLocationsPage'));
const DeviceMapPage = lazy(() => import('./DeviceMapPage'));
const HIKPage = lazy(() => import('./HIKPage'));

const footerStyle = {
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#000',
  color: '#fff',
  padding: '8px 0',
  textAlign: 'center',
  zIndex: 1000,
  fontSize: '0.875rem'
};

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVerificationRequired, setIsVerificationRequired] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [adminMenuOpen, setAdminMenuOpen] = useState(null);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  const muiTheme = useTheme(); // Renamed variable to avoid conflict
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('sm'));

  useEffect(() => {
    const userId = sessionStorage.getItem('userId');
    if (userId) {
      setIsAuthenticated(true);
      const adminStatus = sessionStorage.getItem('isAdmin') === 'true';
      setIsAdmin(adminStatus);
      if (window.location.pathname !== '/') {
        setCurrentPath(window.location.pathname);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      // Clear any sensitive data from memory
      sessionStorage.removeItem('userId');
      // Don't remove items needed for auth flow
    };
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signIn(username, password);
      setIsAuthenticated(true);
      const adminStatus = sessionStorage.getItem('isAdmin') === 'true';
      setIsAdmin(adminStatus);
      if (window.location.pathname !== '/') {
        setCurrentPath(window.location.pathname);
      }
      setNotification({ open: true, message: 'Login successful!', severity: 'success' });
    } catch (error) {
      setNotification({ open: true, message: 'Error logging in: ' + error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setNotification({ open: true, message: 'Passwords do not match', severity: 'error' });
      return;
    }
    setLoading(true);
    try {
      await signUp(username, password);
      setVerificationEmail(username);
      setIsVerificationRequired(true);
      setNotification({ open: true, message: 'Sign up successful! Please check your email for the confirmation code.', severity: 'success' });
    } catch (error) {
      setNotification({ open: true, message: 'Error signing up: ' + error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = () => {
    setIsAuthenticated(false);
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    sessionStorage.clear();
    window.location.href = '/'; // Redirect to the login page
  };

  const handleVerificationSuccess = () => {
    setIsVerificationRequired(false);
    setIsAuthenticated(true);
    const adminStatus = sessionStorage.getItem('isAdmin') === 'true';
    setIsAdmin(adminStatus);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setNotification({ open: true, message: 'Passwords do not match', severity: 'error' });
      return;
    }
    setLoading(true);
    try {
      await resetPassword(username, resetCode, password);
      setNotification({ open: true, message: 'Password reset successful! You can now log in with your new password.', severity: 'success' });
      setIsResetPassword(false);
      setIsSignUp(false);
    } catch (error) {
      setNotification({ open: true, message: 'Error resetting password: ' + error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const hasCFolders = () => {
    const folders = JSON.parse(sessionStorage.getItem('folders') || '[]');
    return folders.some((folder) => folder.toLowerCase().startsWith('c'));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="App">
          <AppBar position="static">
            <Toolbar>
              {isAuthenticated && isSmallScreen && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setDrawerOpen(true)}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                SC Site
              </Typography>
              {isAuthenticated && !isSmallScreen && (
                <div>
                  {isAdmin && (
                    <Button
                      color="inherit"
                      onClick={(e) => setAdminMenuOpen(e.currentTarget)}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      Admin
                    </Button>
                  )}
                  <Button color="inherit" component={Link} to="/file-content">
                    Photos & Timelapse
                  </Button>
                  {hasCFolders() && (
                    <Button color="inherit" component={Link} to="/video-content">
                      Video Footage Record
                    </Button>
                  )}
                  <Button color="inherit" component={Link} to="/file-content-a">
                    ANPR Record
                  </Button>
                  <Button color="inherit" component={Link} to="/camera-info">
                    My Cameras
                  </Button>
                  <Button color="inherit" component={Link} to="/profile">
                    Change Password
                  </Button>
                  <Button color="inherit" onClick={handleSignOut} startIcon={<ExitToAppIcon />}>
                    Sign Out
                  </Button>
                </div>
              )}
            </Toolbar>
          </AppBar>

          <Menu
            anchorEl={adminMenuOpen}
            open={Boolean(adminMenuOpen)}
            onClose={() => setAdminMenuOpen(null)}
          >
            <MenuItem component={Link} to="/ip-addresses" onClick={() => setAdminMenuOpen(null)}>
              Camera List
            </MenuItem>
            <MenuItem component={Link} to="/camera-locations" onClick={() => setAdminMenuOpen(null)}>
              Camera Location History
            </MenuItem>
            <MenuItem component={Link} to="/device-map" onClick={() => setAdminMenuOpen(null)}>
              All Camera Location
            </MenuItem>
            <MenuItem component={Link} to="/report" onClick={() => setAdminMenuOpen(null)}>
              Report View
            </MenuItem>
            <MenuItem component={Link} to="/admin" onClick={() => setAdminMenuOpen(null)}>
              Account Manager
            </MenuItem>
            <MenuItem component={Link} to="/hik" onClick={() => setAdminMenuOpen(null)}>
              HIK Management
            </MenuItem>
          </Menu>

          {/* Drawer for mobile navigation */}
          <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
            <List sx={{ width: 250 }}>
              {isAuthenticated && (
                <>
                  {isAdmin && (
                    <ListItem>
                      <ListItemText primary="Admin" />
                      <List>
                        <ListItem button component={Link} to="/ip-addresses" onClick={() => setDrawerOpen(false)}>
                          <ListItemText primary="Camera List" />
                        </ListItem>
                        <ListItem button component={Link} to="/camera-locations" onClick={() => setDrawerOpen(false)}>
                          <ListItemText primary="Camera Location History" />
                        </ListItem>
                        <ListItem button component={Link} to="/device-map" onClick={() => setDrawerOpen(false)}>
                          <ListItemText primary="All Camera Location" />
                        </ListItem>
                        <ListItem button component={Link} to="/report" onClick={() => setDrawerOpen(false)}>
                          <ListItemText primary="Report View" />
                        </ListItem>
                        <ListItem button component={Link} to="/admin" onClick={() => setDrawerOpen(false)}>
                          <ListItemText primary="Account Manager" />
                        </ListItem>
                        <ListItem button component={Link} to="/hik" onClick={() => setDrawerOpen(false)}>
                          <ListItemText primary="HIK Management" />
                        </ListItem>
                      </List>
                    </ListItem>
                  )}
                  <ListItem button component={Link} to="/file-content" onClick={() => setDrawerOpen(false)}>
                    <ListItemText primary="Photos & Timelapse" />
                  </ListItem>
                  {hasCFolders() && (
                    <ListItem button component={Link} to="/video-content" onClick={() => setDrawerOpen(false)}>
                      <ListItemText primary="Video Footage Record" />
                    </ListItem>
                  )}
                  <ListItem button component={Link} to="/file-content-a" onClick={() => setDrawerOpen(false)}>
                    <ListItemText primary="ANPR Record" />
                  </ListItem>
                  <ListItem button component={Link} to="/camera-info" onClick={() => setDrawerOpen(false)}>
                    <ListItemText primary="My Cameras" />
                  </ListItem>
                  <ListItem button component={Link} to="/profile" onClick={() => setDrawerOpen(false)}>
                    <ListItemText primary="Change Password" />
                  </ListItem>
                  <ListItem button onClick={handleSignOut}>
                    <ListItemText primary="Sign Out" />
                  </ListItem>
                </>
              )}
            </List>
          </Drawer>

          {/* Loading Backdrop */}
          <Backdrop open={loading} sx={{ color: '#fff', zIndex: (muiTheme) => muiTheme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Container maxWidth={false} sx={{ pb: 6 }}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route
                  path="/"
                  element={
                    isVerificationRequired ? (
                      <VerifyPage email={verificationEmail} onVerificationSuccess={handleVerificationSuccess} />
                    ) : isAuthenticated ? (
                      <Navigate to={currentPath !== '/' ? currentPath : '/profile'} />
                    ) : (
                      <Grid container justifyContent="center">
                        <Grid item xs={11} sm={8} md={4}>
                          <Paper elevation={6} sx={{ padding: 4, mt: 8 }}>
                            <Grid container direction="column" alignItems="center">
                              <Avatar sx={{ bgcolor: 'primary.main', mb: 2 }}>
                                <LockOutlinedIcon />
                              </Avatar>
                              <Typography variant="h5">
                                {isSignUp ? 'Sign Up' : isResetPassword ? 'Reset Password' : 'Sign In'}
                              </Typography>
                            </Grid>
                            <Box
                              component="form"
                              onSubmit={
                                isSignUp
                                  ? handleSignUp
                                  : isResetPassword
                                  ? handleResetPassword
                                  : handleSignIn
                              }
                              sx={{ mt: 1 }}
                            >
                              <TextField
                                label="Username"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                              />
                              {isResetPassword ? (
                                <>
                                  <TextField
                                    label="Reset Code"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={resetCode}
                                    onChange={(e) => setResetCode(e.target.value)}
                                  />
                                  <TextField
                                    label="New Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                  />
                                  <TextField
                                    label="Confirm New Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                  />
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                  >
                                    Reset Password
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <TextField
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                  />
                                  {isSignUp && (
                                    <TextField
                                      label="Confirm Password"
                                      type="password"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      value={confirmPassword}
                                      onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                  )}
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                  >
                                    {isSignUp ? 'Sign Up' : 'Login'}
                                  </Button>
                                  <Button
                                    fullWidth
                                    onClick={() =>
                                      isSignUp ? setIsSignUp(false) : setIsSignUp(true)
                                    }
                                    sx={{ mt: 1 }}
                                  >
                                    {isSignUp
                                      ? 'Already have an account? Login'
                                      : 'Need an account? Sign Up'}
                                  </Button>
                                  {!isSignUp && (
                                    <Button
                                      fullWidth
                                      onClick={() => setIsResetPassword(true)}
                                      sx={{ mt: 1 }}
                                    >
                                      Forgot Password?
                                    </Button>
                                  )}
                                </>
                              )}
                            </Box>
                          </Paper>
                        </Grid>
                      </Grid>
                    )
                  }
                />
                {/* Rest of your routes */}
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated} element={ProfilePage} />
                  }
                />
                <Route
                  path="/report"
                  element={
                    <PrivateRoute
                      isAuthenticated={isAuthenticated}
                      isAdminRequired={true}
                      isAdmin={isAdmin}
                      element={ReportPage}
                    />
                  }
                />
                <Route
                  path="/camera-info"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated} element={CameraInfoPage} />
                  }
                />
                <Route
                  path="/file-content-a"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated} element={FileContentPageA} />
                  }
                />
                <Route
                  path="/video-content"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated} element={VideoContentPage} />
                  }
                />
                <Route
                  path="/ip-addresses"
                  element={
                    <PrivateRoute
                      isAuthenticated={isAuthenticated}
                      isAdminRequired={true}
                      isAdmin={isAdmin}
                      element={IPAddressesPage}
                    />
                  }
                />
                <Route
                  path="/camera-locations"
                  element={
                    <PrivateRoute
                      isAuthenticated={isAuthenticated}
                      isAdminRequired={true}
                      isAdmin={isAdmin}
                      element={CameraLocationsPage}
                    />
                  }
                />
                <Route
                  path="/device-map"
                  element={
                    <PrivateRoute
                      isAuthenticated={isAuthenticated}
                      isAdminRequired={true}
                      isAdmin={isAdmin}
                      element={DeviceMapPage}
                    />
                  }
                />
                <Route
                  path="/file-content"
                  element={
                    <PrivateRoute 
                      isAuthenticated={isAuthenticated} 
                      element={FileContentPage} 
                    />
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <PrivateRoute
                      isAuthenticated={isAuthenticated}
                      isAdminRequired={true}
                      isAdmin={isAdmin}
                      element={AdminPage}
                    />
                  }
                />
                <Route
                  path="/hik"
                  element={
                    <PrivateRoute
                      isAuthenticated={isAuthenticated}
                      isAdminRequired={true}
                      isAdmin={isAdmin}
                      element={HIKPage}
                    />
                  }
                />
              </Routes>
            </Suspense>
          </Container>
          <Snackbar
            open={notification.open}
            autoHideDuration={6000}
            onClose={() => setNotification({ ...notification, open: false })}
          >
            <Alert
              onClose={() => setNotification({ ...notification, open: false })}
              severity={notification.severity}
            >
              {notification.message}
            </Alert>
          </Snackbar>
          
          {/* Add the new footer */}
          <Box sx={{ height: '40px' }} /> {/* Spacer to prevent content from being hidden behind fixed footer */}
          <Box component="footer" sx={footerStyle}>
            © {new Date().getFullYear()} 1300SiteCam | All Rights Reserved
          </Box>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
